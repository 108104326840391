<template>
  <Transition name="fade">
    <div
      v-if="isOpen"
      :class="
        $twMerge(
          'fixed inset-0 md:inset-0 z-50 flex size-full items-end overflow-y-auto',
          ' bg-black bg-opacity-70 md:items-center md:justify-center',
        )
      "
    >
      <div
        :class="
          $twMerge(
            'flex h-4/5 w-full flex-col overflow-y-auto rounded-t-lg bg-white',
            'shadow-lg md:mx-auto md:max-w-4xl md:rounded',
          )
        "
      >
        <div class="flex h-10 items-center justify-center">
          <Handle />
        </div>
        <ModalHeader
          :title="title"
          class="sticky top-0 z-50 border-b border-gray-200 bg-white p-4"
          @close="$emit('close')"
        />
        <slot></slot>
      </div>
    </div>
  </Transition>
</template>

<script>
import ModalHeader from "./ModalHeader.vue";
import Handle from "@/components/ui/icons/Handle.vue";

export default {
  name: "Modal",
  components: {
    ModalHeader,
    Handle,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  emits: ["close"],
};
</script>
