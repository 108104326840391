<template>
  <div
    :class="
      $twMerge(
        'rounded-lg px-2.5 py-1 gap-2 text-base font-normal leading-6',
        'max-w-[90%] m-2 flex w-auto',
        isUser && 'flex-row-reverse rounded-md ml-auto',
      )
    "
  >
    <UserIcon v-if="isUser" class="hidden size-6 shrink-0 sm:block" />
    <TitleLead v-else class="hidden size-6 shrink-0 sm:block" />
    <div
      class="message-text break-words px-2"
      :class="{
        'bg-blue-100 text-grey-700': isUser,
        'rounded-lg bg-grey-200 text-grey-700': !isUser,
      }"
    >
      <Markdown :text="content" />
    </div>
  </div>
</template>

<script>
import Markdown from "@/components/ui/Markdown.vue";
import TitleLead from "@/components/ui/icons/TitleLead.vue";
import { UserIcon } from "lucide-vue-next";

export default {
  components: {
    Markdown,
    TitleLead,
    UserIcon,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    role: {
      type: String,
      default: "",
    },
  },
  computed: {
    isUser() {
      return this.role === "user";
    },
  },
};
</script>

<style scoped>
.message-text {
  font-family: "Geomanist", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

.message-image {
  max-width: 200px;
  max-height: 200px;
  border-radius: 10px;
  margin-top: 10px;
  object-fit: cover;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
