<template>
  <div class="flex flex-col overflow-auto">
    <AnyBarMessage
      v-for="message in messages"
      :key="message.id"
      :content="message.content"
      :role="message.role"
    />
    <AnyBarMessage
      v-if="inFlightMessage"
      :content="inFlightMessage"
      role="assistant"
      class="grow"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AnyBarMessage from "./AnyBarMessage.vue";

export default {
  name: "AnyBarMessages",
  components: {
    AnyBarMessage,
  },
  computed: {
    ...mapGetters("anybar", ["messages", "inFlightMessage"]),
  },
};
</script>
