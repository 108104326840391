import { stream } from "@/utils/streaming";

const BASE_PATH = `${process.env.VUE_APP_API_URL ?? ""}/api/v2/assistant`;

export async function postAssistantCompletionStream(
  { context, session_id, language },
  onMessage,
) {
  const url = `${BASE_PATH}/completion`;
  return stream(
    url,
    {
      method: "POST",
      body: JSON.stringify({
        context,
        session_id,
        language: language ?? "PL",
      }),
    },
    onMessage,
  );
}
