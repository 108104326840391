<template>
  <Modal :is-open="isOpen" title="OLX Magic" @close="SET_IS_OPEN(false)">
    <AnyBarMessages class="grow overflow-auto" />
    <AnyBarInput class="mx-2 mb-2 mt-auto" />
  </Modal>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Modal from "@/components/ui/modal/Modal.vue";
import AnyBarInput from "./AnyBarInput.vue";
import AnyBarMessages from "./AnyBarMessages.vue";

export default {
  name: "AnyBarModal",
  components: {
    Modal,
    AnyBarInput,
    AnyBarMessages,
  },
  computed: {
    ...mapGetters("anybar", ["isOpen", "isTextDisabled"]),
  },
  methods: {
    ...mapMutations("anybar", ["SET_IS_OPEN"]),
  },
};
</script>
