<template>
  <template v-if="isEnabled">
    <AnyBarFab />
    <AnyBarModal />
  </template>
</template>

<script>
import AnyBarFab from "./AnyBarFab.vue";
import AnyBarModal from "./AnyBarModal.vue";

const DISABLED_ON = ["staging", "production"];
const isEnabled = !DISABLED_ON.includes(process.env.VUE_APP_STAGE);

export default {
  name: "AnyBar",
  components: {
    AnyBarFab,
    AnyBarModal,
  },
  data() {
    return {
      isEnabled,
    };
  },
};
</script>
