<template>
  <div class="fixed bottom-4 right-4 z-20">
    <BaseButton
      variant="icon"
      floating
      :class="
        $twMerge(
          'size-14 md:size-16 p-3',
          ' border border-magic-800 bg-magic-100 opacity-80',
        )
      "
      @click="SET_IS_OPEN(true)"
    >
      <TitleLead class="size-8 md:size-11" />
    </BaseButton>
  </div>
</template>

<script>
import TitleLead from "@/components/ui/icons/TitleLead.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
import { mapMutations } from "vuex";

export default {
  name: "AnyBarFab",
  components: {
    TitleLead,
    BaseButton,
  },
  methods: {
    ...mapMutations("anybar", ["SET_IS_OPEN"]),
  },
};
</script>
