<template>
  <form
    class="flex rounded-lg border border-gray-200"
    @submit.prevent="handleSubmit"
  >
    <input
      v-model="message"
      type="text"
      class="grow border-none focus:ring-0"
      placeholder="Ask me anything"
    />

    <span class="flex items-center justify-center border-l border-gray-200 p-2">
      <BaseButton variant="icon" type="submit">
        <SendIcon class="size-6" />
      </BaseButton>
    </span>
  </form>
</template>

<script>
import { SendIcon } from "lucide-vue-next";
import BaseButton from "@/components/ui/BaseButton.vue";
import { mapActions } from "vuex";

export default {
  name: "AnyBarInput",
  components: {
    SendIcon,
    BaseButton,
  },
  data() {
    return {
      message: "",
    };
  },
  methods: {
    ...mapActions("anybar", ["sendMessage"]),
    handleSubmit(event) {
      event.preventDefault();
      this.sendMessage(this.message);
      this.message = "";
    },
  },
};
</script>
