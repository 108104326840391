import { postAssistantCompletionStream } from "@/services/AssistantService";
import logger from "@/services/logger";
import { chunkHandler } from "@/utils/streaming";
import { MESSAGE_SEPARATOR } from "../utils/hybrid";

const TEXT_ENABLED_STATUSES = ["idle"];

export default {
  namespaced: true,
  state: {
    messages: [],
    inFlightMessage: null,
    sessionId: null,
    isOpen: false,
    status: "idle",
    error: null,
  },
  getters: {
    messages: (state) => state.messages,
    inFlightMessage: (state) => state.inFlightMessage,
    sessionId: (state) => state.sessionId,
    isOpen: (state) => state.isOpen,
    status: (state) => state.status,
    isTextDisabled: (state) => !TEXT_ENABLED_STATUSES.includes(state.status),
  },
  mutations: {
    SET_IS_OPEN(state, isOpen) {
      state.isOpen = isOpen;
    },
    SET_SESSION_ID(state, sessionId) {
      state.sessionId = sessionId;
    },
    ADD_MESSAGE(state, message) {
      state.messages.push(message);
    },
    SET_STATUS(state, status) {
      state.status = status;
    },
    ADD_IN_FLIGHT_MESSAGE_CHUNK(state, chunk) {
      state.inFlightMessage = (state.inFlightMessage ?? "") + chunk;
    },
    SET_IN_FLIGHT_MESSAGE(state, message) {
      state.inFlightMessage = message;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    async sendMessage({ commit, rootGetters, getters, dispatch }, message) {
      commit("ADD_MESSAGE", { role: "user", content: message });
      commit("SET_STATUS", "loading");
      commit("SET_ERROR", null);
      commit("SET_IN_FLIGHT_MESSAGE", null);

      const url = window.location.href;
      const hybrid_search_id = rootGetters["search/searchId"];
      const context = { type: "magic_page", url, hybrid_search_id, message };
      const language = (
        rootGetters["settings/selectedLocale"] ?? "PL"
      ).toLowerCase();
      const session_id = getters["sessionId"];

      try {
        let buffer = "";
        let content = "";
        await postAssistantCompletionStream(
          { context, session_id, language },
          (chunk) => {
            buffer = chunkHandler(
              buffer + chunk,
              (data) => {
                logger.log("postAssistantCompletionStream", data);
                dispatch("completionSideEffects", data);

                const { message, role, type } = data;
                if (type === "chat_end") {
                  commit("ADD_MESSAGE", { role: "assistant", content });
                  commit("SET_IN_FLIGHT_MESSAGE", null);
                  content = "";
                  return;
                }

                const r = !!message && role === "assistant";
                if (r) {
                  content += message;
                  commit("ADD_IN_FLIGHT_MESSAGE_CHUNK", message);
                }
              },
              MESSAGE_SEPARATOR,
            );
          },
        );

        commit("SET_IN_FLIGHT_MESSAGE", null);
        commit("SET_STATUS", "idle");
      } catch (error) {
        logger.error(error);
        commit("SET_ERROR", error);
      }
    },
    completionSideEffects({ commit }, { type, ...rest }) {
      if (type === "session_creation") {
        commit("SET_SESSION_ID", rest.session_id);
      }
    },
  },
};
