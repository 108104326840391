import { createStore } from "vuex";
import anybar from "./modules/anybar";
import favourites from "./modules/favourites";
import floatingItems from "./modules/floating-items";
import guideActions from "./modules/guide-actions";
import hybridActions from "./modules/hybrid-actions";
import hybridHelper from "./modules/hybrid-helper";
import hybridOnboarding from "./modules/hybrid-onboarding";
import messages from "./modules/messages";
import navigation from "./modules/navigation";
import products from "./modules/products";
import search from "./modules/search";
import selected from "./modules/selected";
import settings from "./modules/settings";
import user from "./modules/user";
import windowSize from "./modules/windowSize";
import { persistance } from "./persistance/persistance";

export default createStore({
  modules: {
    navigation,
    messages,
    settings,
    favourites,
    selected,
    windowSize,
    products,
    user,
    floatingItems,
    hybridActions,
    guideActions,
    hybridHelper,
    search,
    hybridOnboarding,
    anybar,
  },
  plugins: [persistance],
});
